var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1323950423761a543d990649d582ed09a3ff0b26"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? '',
	ignoreErrors: [
		'a.LegacyGlobal.LP_explicit_ignored is not a function',
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.',
		'window.matchMedia is not a function',
		"Can't find variable: _AutofillCallbackHandler",
		"Can't find variable: instantSearchSDKJSBridgeClearHighlight",
		"undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
		"undefined is not an object (evaluating 'window.webkit.messageHandlers[a].postMessage')",
		"undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
		'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
	],
	denyUrls: [/analytics\.tiktok\.com/, /secure\.quantserve\.com/],
	// Disable performance monitoring
	tracesSampleRate: 0,
});
